import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const TrainingScheduleScreen = () => (
  <div className="TrainingScheduleScreen">
    <section id="schedule" className="section">
      <Container>
        <Row>
          <Col md="12">
            <div className="training-schedule">
              <iframe
                title="training-schedule"
                src="https://zr-team-schedule.netlify.app/"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </div>
)

export default TrainingScheduleScreen
