import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import PageHero from '../components/PageHero'
import TrainingScheduleScreen from '../components/Screens/TrainingScheduleScreen'

const TrainingSchedulePage = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('pages.trainingSchedule.hero.title')}>
      <PageHero title={t('pages.trainingSchedule.hero.title')} />
      <TrainingScheduleScreen />
    </Layout>
  )
}

export default TrainingSchedulePage
